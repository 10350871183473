import { Link } from 'react-router-dom';
import lockEye from 'src/assets/icons/lock-eye.svg';
import {
  ExplainabilityRecommendation,
  VehicleStat,
} from 'src/types/graphql-types';
import { Chip, IChipProps, Switch, Tooltip } from '@nirvana/ui-kit';
import ReactMarkdown from 'react-markdown';
import { getBucketChipProps, RiskScoreBucket } from 'src/helpers';
import { createColumnHelper } from '@tanstack/react-table';

export interface VehicleData extends VehicleStat {
  recommendation?: ExplainabilityRecommendation;
  driver?: { name: string };
}

export const VehicleRiskScoreBuckets: Array<RiskScoreBucket> = [
  {
    name: 'Inconclusive',
    graphFill: '#6b7280',
    chipClass: 'default',
    order: 5,
    contains: function (value: number): boolean {
      return value === null;
    },
  },
  {
    name: '0 - 12',
    graphFill: '#25B24A',
    chipClass: 'success',
    order: 1,
    contains: function (value: number): boolean {
      return value >= 0 && value <= 12;
    },
  },
  {
    name: '12 - 25',
    graphFill: '#FFB900',
    chipClass: 'gold',
    order: 2,
    contains: function (value: number): boolean {
      return value > 12 && value <= 25;
    },
  },
  {
    name: '25 - 65',
    graphFill: '#FF823C',
    chipClass: 'warning',
    order: 3,
    contains: function (value: number): boolean {
      return value > 25 && value <= 65;
    },
  },
  {
    name: '65 - 100',
    graphFill: '#FA3252',
    chipClass: 'error',
    order: 4,
    contains: function (value: number): boolean {
      return value > 65 && value <= 100;
    },
  },
];

function getChipProps(value: number): IChipProps {
  return getBucketChipProps(VehicleRiskScoreBuckets, value);
}

export function leaderboardColumns(
  reportId: string,
  hasTelematicsConnection: boolean,
) {
  const columnHelper = createColumnHelper<VehicleData>();
  return [
    columnHelper.accessor('vehicleTSPName', {
      header: 'Truck Number',
      cell: ({ getValue }) => (
        <Switch
          fallback={
            <p className="text-sm font-normal text-gray-600">{getValue()}</p>
          }
        >
          <Switch.Match when={!hasTelematicsConnection}>
            <Tooltip title="Requires Telematics Connection" arrow>
              <img src={lockEye} className="w-5 h-5" />
            </Tooltip>
          </Switch.Match>
          <Switch.Match when={getValue() === null}>
            <Tooltip title="Data not available" arrow>
              <span>N/A</span>
            </Tooltip>
          </Switch.Match>
        </Switch>
      ),
    }),
    columnHelper.accessor('vIN', {
      header: 'Vehicle',
      cell: ({ getValue, row }) => {
        return (
          <div>
            <p className="text-sm font-normal text-secondary-dark">
              {getValue()}
            </p>
            <p className="text-sm font-normal text-text-hint">
              {row.original.vehicleName}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor('driver.name', {
      header: 'Driver',
      cell: ({ getValue }) => <div className="text-sm">{getValue()}</div>,
      sortUndefined: 'last',
    }),
    columnHelper.accessor('riskScore', {
      header: 'Driver Safety Score',
      sortUndefined: 'last',
      sortDescFirst: false,
      meta: {
        tooltipText:
          "An individual vehicle's Nirvana Safety Score. A lower score indicates safer driving practices.",
      },
      cell: ({ getValue }) => (
        <Switch fallback={<Chip {...getChipProps(getValue() ?? 0)} />}>
          <Switch.Match when={!hasTelematicsConnection}>
            <Tooltip title="Requires Telematics Connection" arrow>
              <img src={lockEye} className="w-5 h-5" />
            </Tooltip>
          </Switch.Match>
          <Switch.Match when={getValue() === undefined}>
            <Tooltip title="Data not available" arrow>
              <span>N/A</span>
            </Tooltip>
          </Switch.Match>
        </Switch>
      ),
    }),
    columnHelper.accessor('recommendation', {
      header: 'Recommendation',
      enableSorting: false,
      meta: {
        tooltipText:
          'The primary driver behavior negatively impacting their safety score. Addressing this is crucial for improving individual and overall fleet safety performance.',
      },
      cell: ({ getValue, row }) => {
        const value = getValue();
        if (
          !value ||
          !value.markdown ||
          !value.title ||
          !row.original.riskScore ||
          row.original.riskScore <= 10
        ) {
          return <Chip label="N/A" className="text-text-hint" />;
        }
        return (
          <Tooltip
            title={
              <div className="max-w-xs">
                <ReactMarkdown>{value.markdown}</ReactMarkdown>
              </div>
            }
            arrow
          >
            <div>
              <Chip label={value.title} color="error" />
            </div>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor('violationSeverityTimeWeighted', {
      header: 'Violation Score',
      meta: {
        tooltipText:
          'Violations and their weighting will take effect once the latest BASIC scores are published by the FMCSA',
      },
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={`/${reportId}/insights/violations?vINs=${row.original.vIN}`}
            className="px-3 py-1 font-medium rounded text-primary-dark bg-primary-extraLight hover:bg-primary-main hover:text-white"
          >
            {getValue()}
          </Link>
        );
      },
    }),
  ];
}
