import { UserProfileResponse } from '@nirvana/api/auth';

export interface ISegmentTrackPageView {
  category?: string;
  name: string;
  properties?: any;
  options?: any;
}

export const trackPageView = (request: ISegmentTrackPageView) => {
  window.analytics.page(
    request.category,
    request.name,
    request.properties,
    request.options,
  );
};

export interface ISegmentTrackUserIdentify {
  userId: string;
  traits: any;
  options?: any;
}

const identifyOnPostHog = (userId: string, traits: object = {}, time = 100) => {
  if (typeof window.posthog.identify === 'function') {
    window.posthog.identify(userId, traits);
    return;
  }
  if (time > 5000) {
    return;
  }
  setTimeout(() => identifyOnPostHog(userId, traits, time * 2), time);
};

const identifyOnSegment = (userId: string, traits: object = {}, time = 100) => {
  if (typeof window.analytics.identify === 'function') {
    window.analytics.identify(userId, traits);
    return;
  }
  if (time > 5000) {
    return;
  }
  setTimeout(() => identifyOnSegment(userId, traits, time * 2), time);
};

const identifyUser = (userId: string, traits: object = {}) => {
  identifyOnSegment(userId, traits);
  identifyOnPostHog(userId, traits);
};

export const trackUserIdentify = (user: UserProfileResponse) => {
  const data: ISegmentTrackUserIdentify = {
    userId: user.id,
    traits: {
      agencyId: user.defaultAgencyId,
      name: user.name,
      email: user.email,
      userType: user.userType,
    },
  };
  identifyUser(data.userId, data.traits);
};

export enum SegmentEventTrack {
  LoginPageView = 'SPLoginPageViewed',
  HomePageView = 'SPHomePageViewed',
  OverviewPageView = 'SPOverviewPageViewed',
  ProfilePageView = 'SPProfilePageViewed',
  RecommendationsPageView = 'SPRecommendationsPageViewed',
  FlagsPageView = 'SPFlagsPageViewed',
  ScoresPageView = 'SPScoresPageViewed',
  TelematicsCompletePageView = 'SPTelematicsCompletePageViewed',
  ViolationsPageView = 'SPViolationsPageViewed',
  DriversPageView = 'SPDriversPageViewed',
  VehiclesPageView = 'SPVehiclesPageViewed',
  HeatmapPageView = 'SPHeatmapPageViewed',
  SearchDOT = 'SPDotSearched',
  StarDOT = 'SPDotStarred',
  StarredDOTDelete = 'SPStarredDotDeleted',
  StarredDOTView = 'SPStarredDotViewed',
  SidebarLinkSelect = 'SPSidebarLinkSelected',
  DetailsView = 'SPOverviewDetailsViewed',
  RecommendationView = 'SPRecommendationViewed',
  RecommendationDetailsExpand = 'SPRecommendationDetailsExpanded',
  RecommendationDetailsCollapse = 'SPRecommendationDetailsCollapsed',
  RecommendationsFilter = 'SPRecommendationsFilterApplied',
  FlagView = 'SPFlagViewed',
  FlagDetailsExpand = 'SPFlagDetailsExpanded',
  FlagDetailsCollapse = 'SPFlagDetailsCollapsed',
  FlagsFilter = 'SPFlagsFilterApplied',
  DateRangeFilter = 'SPDateRangeFilterApplied',
  TelematicsConnect = 'SPTelematicsConnectAttempted',
  OtherTelematicsConnect = 'SPOtherTelematicsConnectAttempted',
  DotPin = 'SPDotPinAttempted',
  ShareDOT = 'SPShareDotAttempted',
  Login = 'SPLoginAttempted',
  UnlockDOT = 'SPDotPinEntered',
  UnlockDOTFailed = 'SPDotPinEnteredFailed',
  UnlockDOTSuccessful = 'SPDotPinEnteredSucessfully',
  PerksPageView = 'SPPerksPageView',
  PerksLinkClicked = 'SPPerksLinkClicked',
  RecommendationFeedback = 'SPRecommendationFeedback',
  TrainingPageView = 'SPTrainingPageViewed',
  TrainingVideoPercentagePlayed = 'SPTrainingVideoPercentagePlayed',
  TrainingVideoPlayed = 'SPTrainingVideoPlayed',
  UserActivateSuccessful = 'SPUserActivateSuccessful',
  CompleteFMCSALogin = 'SPCompleteTwoFA',
  CSAViolationsTimelineClicked = 'SPCSAViolationsTimelineClicked',
  CSAViolationsTimelineHovered = 'SPCSAViolationsTimelineHovered',
  SafetyManagerBookingClicked = 'SPSafetyManagerBookingClicked',
  ClaimsPageView = 'SPClaimsPageViewed',
  NotificationPreferencesUnsubscribe = 'SPNotificationPreferencesUnsubscribe',
  FNOLFormPageView = 'SPFNOLFormPageViewed',
  FNOLFormSubmissions = 'SPFNOLFormSubmissions',
}

export interface ISegmentTrackEvent {
  event: SegmentEventTrack;
  properties?: any;
  options?: any;
}

export const trackEvent = (request: ISegmentTrackEvent) => {
  const properties = request.properties !== undefined ? request.properties : {};
  const options = request.options !== undefined ? request.options : {};
  // Set default Slack integration to false
  const defaultIntegrations = { All: true, Slack: false };
  options.integrations =
    options.integrations !== undefined
      ? { ...defaultIntegrations, ...options.integrations }
      : defaultIntegrations;
  window.analytics.track(request.event, properties, options);
};
